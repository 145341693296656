<template>
    <footer class="main-footer">
      <strong>Copyright &copy; {{ year }}.</strong> Desenvolvido por WCSA.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 2.0
      </div>
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
</template>

<script>

export default {
   name: "Footer",
   computed: {
      year () {
         let now = new Date();
         return now.getFullYear();
      }
   }
}
</script>
